import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ChatWidget from './ChatWidget';
import './DemoColdplay.css'; // Renamed CSS file for clarity
import TestSolution from './images/testsolution.webp';

const ColdplayAIPage = () => {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    window.embeddedChatbotConfig = {
      chatbotId: '457ccc57-059b-48d7-be40-b6e35a24fbdb', // Replace with your Coldplay AI Assistant's chatbot ID
      account: 'AISSIE',
    };
    // Optional: Dynamically load chat widget script if necessary
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  // Generate structured data for the AI Assistant page
  const generateAIPageSchema = () => {
    const aiPageSchema = {
      '@context': 'https://schema.org',
      '@type': 'WebPage',
      name: 'Coldplay AI Assistant',
      description:
        "Explore Coldplay's Australia Concerts AI Assistant, designed to provide information about upcoming shows in Sydney and Melbourne. Trained on official and affiliated websites, it offers answers to queries about concert details, tickets, travel, and more.",
      url: 'https://www.aissie.com.au/coldplay-demo',
      image: 'https://www.aissie.com.au/images/testsolution.webp', // Update with the correct image URL
    };
    return JSON.stringify(aiPageSchema);
  };

  return (
    <div className="coldplay-ai-container">
      <Helmet>
        <title>Coldplay AI Assistant</title>
        <meta
          name="description"
          content="Explore Coldplay's Australia Concerts AI Assistant, designed to provide information about upcoming shows in Sydney and Melbourne. Trained on official and affiliated websites, it offers answers to queries about concert details, tickets, travel, and more."
        />
        <link rel="canonical" href="https://www.aissie.com.au/coldplay-demo" />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: generateAIPageSchema() }}
        />
      </Helmet>

      <header className="first-screen-coldplay-ai">
        <div className="ai-text-block">
          <h1>Coldplay Concerts AI Assistant</h1>
          <p className="hero_subhead">
            Welcome to the Coldplay Australia Concerts AI Assistant Demo! This assistant provides
            information about Coldplay's concerts in Sydney and Melbourne, happening in October and
            November this year. Whether you’re seeking concert details or travel information, AIssie AI
            is here to help. The assistant has been trained using data from the following trusted sources:
          </p>
          <ul className="ai-info-list">
            <li>
              <a href="https://www.marvelstadium.com.au">Marvel Stadium</a> – Venue information for
              Melbourne concerts.
            </li>
            <li>
              <a href="https://www.ptv.vic.gov.au/">Public Transport Victoria</a> – Travel information in
              Victoria.
            </li>
            <li>
              <a href="https://en.wikipedia.org">Wikipedia</a> – General information about Coldplay and
              Ayra Starr.
            </li>
            <li>
              <a href="https://www.transportnsw.info">Transport for NSW</a> – Travel information in New
              South Wales.
            </li>
            <li>
              <a href="https://www.accorstadium.com.au/">Accor Stadium</a> – Venue information for Sydney
              concerts.
            </li>
            <li>
              <a href="https://www.coldplay.com">Coldplay</a> – Official Coldplay website.
            </li>
            <li>
              <a href="https://www.humanbiography.com/portfolio-pages/emmanuel-kelly">
                Human Biography: Emmanuel Kelly
              </a>{' '}
              – Information about supporting artist Emmanuel Kelly.
            </li>
            <li>
              <a href="https://www.sydneyolympicpark.nsw.gov.au/parking-faqs">Sydney Olympic Park</a> –
              Parking FAQs.
            </li>
          </ul>
        </div>

        <div className="image-placeholder">
          <img
            src={TestSolution}
            alt="AI Assistant demonstrating FAQ functionality"
            className="hero-image-faq"
          />
        </div>
      </header>

      <footer>
        <div id="coldplay-chat"></div>
        <ChatWidget />
      </footer>
    </div>
  );
};

export default ColdplayAIPage;
