import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from './Navigation';
import Footer from './Footer';
import HomePage from './HomePage';
import FAQPage from './FAQPage';
import ContactUs from './ContactUs';
import Privacy from './Privacy';
import Terms from './Terms';
import Connect from './Connect'
import Login from './Login'
import Pricing from './PricingPage'
import DemoColdplay from './DemoColdplay'
import DemoOSvC from './DemoOSvC'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './App.css';
import NotFound from './NotFound';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
            backgroundColor: '#06525B',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#092F36', // Add your desired hover colour here
            },
          }),
          ...(ownerState.variant === 'text' &&
            ownerState.color === 'primary' && {
            color: '#092F36',
            borderColor: ' #092F36',
            '&:hover': {
              backgroundColor: '#e5e5e5',
              borderColor: '#092F36', // Add your desired hover colour here
            },
          }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'primary' && {
            color: '#092F36',
            borderColor: ' #092F36',
            '&:hover': {
              backgroundColor: '#e5e5e5',
              borderColor: '#092F36', // Add your desired hover colour here
            },
          })
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          color: '#092F36',
        },
        icon: {
          color: '#092F36',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#092F36',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#092F36',
          },
        },
        notchedOutline: {
          borderColor: '#092F36',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#e5e5e5',
            color: '#092F36',
            '&:hover': {
              backgroundColor: '#d4d4d4',
            },
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          borderRight: '1px solid #ccc',
          minWidth: '13rem',
        },
        indicator: {
          backgroundColor: '#092F36', // Change the color of the tab indicator
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#092F36',
            backgroundColor: '#f2f2f2',
          },
          '&:hover': {
            backgroundColor: '#e5e5e5',
          },
        },
      },
    },
    // Add the style overrides for the Dialog components
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: '#f5f5f5', // Change the background color
          color: '#06525B', // Change the text color
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          //backgroundColor: '#06525B', // Change the background color of the title
          //color: '#fff', // Change the text color of the title
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          backgroundColor: '#f5f5f5', // Change the background color of the content
          color: '#06525B', // Change the text color of the content
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          backgroundColor: '#f5f5f5', // Change the background color of the content
          color: '#06525B', // Change the text color of the content
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#092F36',

          '&.Mui-focused': {
            color: '#06525B',
          },
        }
      }
    }
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>

      <Router>
        <div className="App">
          <Navigation />
          <div className="content">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/demo" element={<FAQPage />} />
              <Route path="/contactus" element={<ContactUs />} />
              <Route path="/privacy-policy" element={<Privacy />} />
              <Route path="/terms-and-conditions" element={<Terms />} />
              <Route path="/connect" element={<Connect />} />
              <Route path="/login" element={<Login />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/coldplay-demo" element={<DemoColdplay />} />
              <Route path="/osvc-demo" element={<DemoOSvC />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="*" element={<NotFound />} />



            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </ThemeProvider>

  );
}

export default App;
