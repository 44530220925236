import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navigation.css';
import Logo from './images/logolight6.webp';

const Navigation = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isDemoExpanded, setIsDemoExpanded] = useState(false); // State for Demo submenu
  const navRef = useRef(); // Ref for the nav
  const location = useLocation(); // To get the current path

  const handleNavItemClick = () => {
    setIsNavExpanded(false);
    setIsDemoExpanded(false); // Close Demo submenu when any nav item is clicked
  };

  const handleDemoClick = () => {
    // Toggle Demo submenu on mobile
    setIsDemoExpanded(prevState => !prevState);
  };

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setIsNavExpanded(false);
      setIsDemoExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    // Close navigation and submenu on route change
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [location]);

  return (
    <nav className="navbar" ref={navRef}>
      <div className="nav-brand">
        <Link to="/" onClick={handleNavItemClick}>
          <img src={Logo} alt="AI Assistant" className="nav-image" />
        </Link>
      </div>
      <button
        className="hamburger"
        onClick={() => setIsNavExpanded(prev => !prev)}
        aria-label="Toggle navigation menu"
      >
        ≡
      </button>
      <ul className={`nav-items ${isNavExpanded ? 'expanded' : ''}`}>
        <li>
          <Link to="/pricing" onClick={handleNavItemClick}>
            Pricing
          </Link>
        </li>
        <li className="submenu">
          {/* On desktop, the submenu is handled via CSS hover.
              On mobile, clicking toggles the submenu */}
          <button
            className={`submenu-button ${isDemoExpanded ? 'expanded' : ''}`}
            onClick={handleDemoClick}
            aria-haspopup="true"
            aria-expanded={isDemoExpanded}
          >
            Demo <span className="arrow"></span>
          </button>
          <ul className={`submenu-items ${isDemoExpanded ? 'expanded' : ''}`}>
            <li>
              <Link to="/demo" onClick={handleNavItemClick}>
                Council AI Assistant
              </Link>
            </li>
            <li>
              <Link to="/coldplay-demo" onClick={handleNavItemClick}>
                Coldplay Concert AI Assistant
              </Link>
            </li>
            <li>
              <Link to="/osvc-demo" onClick={handleNavItemClick}>
                Oracle Service Cloud
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/contactus" onClick={handleNavItemClick}>
            Contact Us
          </Link>
        </li>
        <li>
          <a href="https://app.aissie.com.au" onClick={handleNavItemClick}>
            Login / Signup
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
